// COLORS 

$light-color: #FFFFFF
$primary-color: #A9C5BA
$secondary-color: #00000029
$text-color: #707070
$paida-app-dark-color: #000000
$main-paida-color: #a9c5ba
$paida-purple-color: #B4ACD9
$paida-dark-purple-color: #938bbc


// TEXT-STYLES
@mixin text-big
  font-family: 'Mulish', sans-serif
  font-size: 30px

@mixin text-medium
  font-family: 'Mulish', sans-serif
  font-size: 16px

@mixin text-small
  font-family: 'Mulish', sans-serif
  font-size: 14px

@mixin text-logo
  font-family: 'Nunito', sans-serif
  font-size: 35px

// BREAKPOINTS

$breakpoint-tablet: 915px
$breakpoint-phone: 640px
